export function formatDuration(d: number) {
  const totalSecs = Math.round(d / 1000);
  if(totalSecs == 0) {
    return "<1s";
  }

  const hours = Math.floor(totalSecs / 3600);
  const minutes = Math.floor((totalSecs % 3600) / 60);
  const seconds = totalSecs % 60;
  return [hours ? `${hours}h` : "", (hours || minutes) ? `${minutes}m` : "", `${seconds}s`].join("");
}

export function formatCommand(command: string[]) {
  function formatArg(arg: string) {
    if(arg.length == 0) {
      return "''";
    } else if(arg.match(/[^\w%+,./:=@_-]/)) {
      return `'${arg.replace("'", `'"'"'`)}'`; // eslint-disable-line quotes
    } else {
      return arg;
    }
  }
  return command.map(formatArg).join(" ");
}

export interface ParsedLog {
  logType: "ERR" | "LOG" | "INVALID";
  body: string[];
  headers?: Record<"Date"|"Command"|"User"|"Host"|"Job-ID", string>;
  footers?: Record<"Date"|"Exit", string>;
}

export function parseLog(log: string): ParsedLog {
  const lines = log.split(/\n/);
  if(lines[0] == "CRONERR") {
    return {logType: "ERR", body: lines.slice(1)};
  } else if(lines[0] !== "CRONLOG") {
    console.error("bad magic string");
    return {logType: "INVALID", body: lines};
  }

  const headers: Record<string, string> = {};
  const footers: Record<string, string> = {};
  const body: string[] = [];

  let i = 1;

  for(; i < lines.length; i++) {
    if(lines[i] === "") {
      i++;
      break;
    }
    const m = lines[i].match(/^([^:]+)\s*:\s*(.+)$/);
    if(!m) {
      console.error("bad header", lines[i]);
      return {logType: "INVALID", body: lines.slice(1)};
    }
    headers[m[1]] = m[2];
  }

  if(i == lines.length) {
    console.error("unexpected eof (in headers)");
    return {logType: "INVALID", body: lines.slice(1)};
  }

  for(; i < lines.length; i++) {
    if(lines[i] === "") {
      i++;
      break;
    }
    if(!lines[i].match(/^\d+\.\d+ [!>]/)) {
      console.error("bad body line", lines[i]);
      return {logType: "INVALID", body: lines.slice(1)};
    }
    body.push(lines[i]);
  }

  if(i == lines.length) {
    console.error("unexpected eof (in body)");
    return {logType: "INVALID", body: lines.slice(1)};
  }

  for(; i < lines.length; i++) {
    if(lines[i] === "" && i == lines.length - 1) {
      continue;
    }
    const m = lines[i].match(/^([^:]+)\s*:\s*(.+)$/);
    if(!m) {
      console.error("bad footer", lines[i], lines.length - 1, i);
      return {logType: "INVALID", body: lines.slice(1)};
    }
    footers[m[1]] = m[2];
  }

  return {logType: "LOG", headers, footers, body};
}
