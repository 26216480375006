import React from "react";
import ReactDOM from "react-dom";
import {Route, BrowserRouter, Routes, Link} from "react-router-dom";

import type {ISyncResult, ILoggedJob, IRegisteredJob, IJob, BreadcrumbComponent} from "./types";
import JobsList from "./jobs-list";
import Job from "./job";
import Log from "./log";
import "./index.css";

interface State {
  loggedJobs?: ILoggedJob[];
  registeredJobs?: IRegisteredJob[];
  syncResult?: ISyncResult;
  isSyncing: boolean;
  showSyncErrors: boolean;
  breadcrumb: BreadcrumbComponent[];
  gen: number;
}

class Application extends React.Component<Record<string, never>, State> {
  constructor(props: Record<string, never>) {
    super(props);
    this.state = {
      isSyncing: false,
      showSyncErrors: false,
      breadcrumb: [],
      gen: 0,
    };
  }

  componentDidMount() {
    this.doSync();
  }

  doSync = async (e?: React.MouseEvent<any>) => {
    e?.preventDefault?.();
    this.setState({isSyncing: true});
    const syncResult = await fetch("/api/sync", {method: "POST"}).then(r => r.json()).catch(err => {
      console.error(err);
      alert("Failed to do synchronization");
    });

    const [loggedJobs, registeredJobs] = await Promise.all([
      fetch("/api/logs").then(res => res.json()).catch(err => {
        console.error(err);
        alert("Failed to load logs summary");
      }),
      fetch("/api/jobs").then(res => res.json()).catch(err => {
        console.error(err);
        alert("Failed to load jobs");
      }),
    ]);

    this.setState({isSyncing: false, syncResult, loggedJobs, registeredJobs, showSyncErrors: true, gen: this.state.gen + 1});
  };

  doShowSyncErrors = (e?: React.MouseEvent<any>) => {
    e?.preventDefault?.();
    this.setState({showSyncErrors: true});
  };

  setBreadcrumb = (breadcrumb: BreadcrumbComponent[]) => {
    if(this.state.breadcrumb.length !== breadcrumb.length) {
      this.setState({breadcrumb});
      return;
    }
    if(this.state.breadcrumb.some((c, i) => c.label !== breadcrumb[i].label || c.to !== breadcrumb[i].to)) {
      this.setState({breadcrumb});
    }
  };

  renderModal() {
    const doClose = () => this.setState({showSyncErrors: false});
    const {syncResult} = this.state;
    return <>
      <div className="modal" tabIndex={-1} role="dialog" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Sync Error</h4>
              <button type="button" className="btn-close" aria-label="Close" onClick={doClose}></button>
            </div>
            <div className="modal-body">
              {syncResult?.error && <><h5>Error</h5><p><span className="font-monospace">{syncResult!.error}</span></p></>}
              {syncResult?.output && <><h5>Script output</h5><pre style={{overflow: "visible"}}><code>{syncResult!.output}</code></pre></>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={doClose}>Close</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" />
    </>;
  }

  renderNav() {
    const {syncResult, isSyncing, breadcrumb} = this.state;
    return <header className="navbar navbar-dark bg-dark">
      <nav className="container-fluid">
        <span className="navbar-text">
          <div className="breadcrumb mb-0 text-light">
            <Link className="breadcrumb-item text-light text-decoration-none" to="/"><i className="bi-house"/></Link>
            {breadcrumb.map(c => c.to ?
              <Link key={c.label} className="breadcrumb-item text-light text-decoration-none" to={c.to}>{c.label}</Link> :
              <span key={c.label} className="breadcrumb-item text-light">{c.label}</span>)}
          </div>
        </span>
        <span className="navbar-text ms-auto">Last Refresh: {syncResult?.syncDate ? new Date(syncResult!.syncDate).toLocaleString() : "never"}</span>

        {/* minWidth and minHeight are here to avoid navbar resizing when switching between spinner/button */}
        <ul className="navbar-nav flex-row align-items-center" style={{minHeight: 42}}>
          <li className="nav-item" style={{minWidth: 36}}>
            {isSyncing ?
              <a className="nav-link ms-2 py-0" href="#" onClick={e => e.preventDefault()}><div className="text-light spinner-border spinner-border-sm" style={{height: 24, width: 24}}></div></a> :
              <a className="nav-link ms-2 py-0" href="#" onClick={this.doSync}><i className="bi-arrow-clockwise text-success fs-3"></i></a>}
          </li>
        </ul>
        <ul className="navbar-nav flex-row align-items-center">
          {syncResult?.success === false &&
            <li className="nav-item">
              <a className="nav-link ms-2 py-0" href="#" onClick={this.doShowSyncErrors}><i className="bi-exclamation-triangle text-warning fs-3"></i></a>
            </li>}
        </ul>
      </nav>
    </header>;
  }

  render() {
    const {loggedJobs, registeredJobs, showSyncErrors, syncResult, gen} = this.state;

    const jobs: Record<string, IJob> | null = {};
    for(const loggedJob of loggedJobs ?? []) {
      jobs[loggedJob.jobId] ??= {id: loggedJob.jobId};
      jobs[loggedJob.jobId].logs = loggedJob;
    }
    for(const registeredJob of registeredJobs ?? []) {
      jobs[registeredJob.description.id] ??= {id: registeredJob.description.id};
      jobs[registeredJob.description.id].info = registeredJob;
    }

    return <div style={{width: "100vw", height: "100vh", overflow: "auto"}}>
      <div className="container">
        <BrowserRouter>
          {showSyncErrors && syncResult?.success === false && this.renderModal()}
          {this.renderNav()}
          <Routes>
            <Route path="/" element={<JobsList key={gen} jobs={jobs} setBreadcrumb={this.setBreadcrumb} />} />
            <Route path="/jobs/:jobId" element={<Job key={gen} jobs={jobs} setBreadcrumb={this.setBreadcrumb} />} />
            <Route path="/jobs/:jobId/logs/:logId" element={<Log key={gen} jobs={jobs} setBreadcrumb={this.setBreadcrumb} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>;
  }
}

window.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Application />, document.getElementById("root"));
});
